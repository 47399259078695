import {StockStatus} from "../../components/bikeDetails/BikeDetails"

export const getBikesbyCat = (bikes, category) => bikes?.filter(bike => {
	return validate(bike?.categories, category)
})

const validate = (group, name) => {
	if (!group) {
		return false
	}

	for (let i = 0; i < group?.length; i++) {
		if (group?.[i].name === name) {
			return true
		}
	}

	return false
}

export const getBikesbyCatIDs = (bikes, categories, includeOutOfStock = false) => bikes?.filter(bike => categories.every(cat => bike.categories.map(cat => cat.id).includes(parseInt(cat, 10)))).filter(bike => includeOutOfStock || bike.stock_status === StockStatus.inStock || bike.stock_status === StockStatus.onBackOrder)

export const getBikesbyCatSlugs = (bikes, categories, includeOutOfStock = false) => bikes?.filter(bike => categories?.every(cat => bike?.categories?.map(cat => cat.slug).includes(cat)))?.filter(bike => includeOutOfStock || bike.stock_status === StockStatus.inStock || bike.stock_status === StockStatus.onBackOrder)

export const getOutOfStockBikesbyCatSlugs = (bikes, categories) => bikes?.filter(bike => categories?.every(cat => bike?.categories?.map(cat => cat?.slug)?.includes(cat)))?.filter(bike => bike.stock_status === StockStatus.outOfStock)

