import React, {useContext} from "react"
import Typography from "../Typography/Typography"
import {useTranslation} from "next-i18next"
import OrganizationContext from "../../../context/OrganizationContext"
import DataContext from "../../../context/DataContext"
import {getOrganizationDataFromStrapi} from "../../../utility/strapi/helpers"

type Props = {
    instanceShowHPText:boolean
}
function CustomInstancesBlockText({instanceShowHPText}: Props) {
	const {organizationSlug} = useContext(OrganizationContext)
	const {pageProps} = useContext(DataContext)
	const organizations = pageProps?.strapiContent?.data?.organizations

	const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)
	const {t} = useTranslation()
	return (
		<div className="pt-5 container">{instanceShowHPText && process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && organizationData?.home && (
			<>
				<div
					className={"d-md-none d-flex align-items-center justify-content-start gap-2 text-left"}>
					<img alt={organizationData?.companyName}
						src={organizationData?.companyIcon}
						height={26}
						width={"auto"}/>
					<Typography semanticTag="h2"
						variant={"heading2"}
					>
						{t(organizationData?.home?.subTitle).replace("instance", organizationData?.companyName)}
					</Typography>
				</div>
				<div className={"d-none mb-2 col-12 gap-3 d-md-flex align-items-center justify-content-center text-center}"}>
					<img alt={organizationData?.companyName}
						src={organizationData?.companyIcon}
						height={32}
						width={"auto"}/>
					<Typography style={{whiteSpace: "nowrap"}}
						semanticTag="h2"
						variant={ "heading2Black"} >
						{t(organizationData?.home?.subTitle).replace("instance", organizationData?.companyName)}
					</Typography>
				</div>
			</>
		)}
		{instanceShowHPText && Boolean(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) && organizationData?.home && (
			<Typography semanticTag="p"
				variant={"bodyLg"}
				className={"mb-2 col-12 text-left text-md-center"}>
				{t(organizationData?.home?.text).replace("instance", organizationData?.companyName)}
			</Typography>
		)

		}</div>
	)
}

export default CustomInstancesBlockText
