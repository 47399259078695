import React, {useContext} from "react"
import PropTypes from "prop-types"
import Head from "next/head"
import {VWOScript} from "vwo-smartcode-nextjs"

type Props = {
    children: React.ReactNode
}
const MbpHead: React.FC<Props> = ({children}) => {
	const hubspotButtonCSS = `
    #hubspot-messages-iframe-container {
        margin-bottom: 150px!important;
    }
`

	return (
		<Head>

			<link rel="icon"
				href="/assets/images/favicon.png"/>
			<VWOScript accountId="1056090" />
			<script
				data-partytown-config
				dangerouslySetInnerHTML={{
					__html: `function hotjar(h, o, t, j, a, r) {
	h.hj = h.hj || function () {
		// eslint-disable-next-line prefer-rest-params
		(h.hj.q = h.hj.q || []).push(arguments)
	}

	h._hjSettings = {hjid: 2557500, hjsv: 6}
	a = o.getElementsByTagName("head")[0]
	r = o.createElement("script")
	r.async = 1
	r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
	a.appendChild(r)
}

hotjar(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")`}}/>
			<script type="text/javascript"
				id="hs-script-loader"
				async
				defer
				src="//js.hs-scripts.com/6857669.js"/>
			<script
				dangerouslySetInnerHTML={{
					__html: `function tagManager (w, d, s, l, i) {
						w[l] = w[l] || []
						w[l].push({
							"gtm.start":
								new Date().getTime(), event: "gtm.js"
						})
						const f = d.getElementsByTagName(s)[0]
						const j = d.createElement(s)
						
						const dl = l !== "dataLayer" ? "&l=" + l : ""
						j.async = true
						j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
						f.parentNode.insertBefore(j, f)
					}
					tagManager(window, document, "script", "dataLayer", "${process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? "GTM-KNJR5CQ" : "GTM-KL4PZK3"}")`
				}}
			/>
			<script
				dangerouslySetInnerHTML={{
					__html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "o1hotjiezw");`
				}}
			/>
			<script async
				src="https://www.googletagmanager.com/gtag/js?id=AW-702134511"/>
			<script
				dangerouslySetInnerHTML={{
					__html: `window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'AW-702134511');`
				}}
			/>
			<script
				dangerouslySetInnerHTML={{
					__html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("2804466266");`
				}}
			/>
			<meta name="google-site-verification"
				content="wPgWGUOMP8UXNvvktSzfgb4dknTbWEDKD4XRHUFtsXk" />
			<meta charSet="UTF-8" />
			{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE === "true" && <meta name="robots"
				content="noindex"/>}
			<style>{hubspotButtonCSS}</style>
			{children}
		</Head>
	)
}

MbpHead.propTypes = {
	children: PropTypes.node.isRequired
}

export default MbpHead

