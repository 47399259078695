/* eslint-disable no-trailing-spaces */
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import CatBikesListing from "../../bikesCollection/CatBikesListing"
import DataContext from "../../../context/DataContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import BikesCollectionItem from "../../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import ComparisonPLPFixedComponent from "../../bikesCollection/ComparisonPLPFixedComponent"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {useRouter} from "next/router"
import useCache from "../../../hooks/useCache"
import {endpoints} from "../../../constants/API"
import Spinner from "../../reusable/Spinner"
import {CustomHomeCatBoxesSlugsList, HomeCatBoxesSlugsList, HomeCatsContentData} from "../../../constants/TopCategories"
import MainCategoryTabs from "./MainCategoryTabs"
import SubCategoryTabs from "./SubCategoryTabs"
import HomeCatBoxesHeader from "./HomeCatBoxesHeader"
import {ErrorBoundary} from "@sentry/react"
import {debounce} from "lodash"
import LayoutContext from "../../../context/LayoutContext"
import HomeCatsButton from "./HomeCatsButton"
import SliderContent from "../../reusable/components/SliderContent/SliderContent"
import urlParams from "../../../utility/UrlParams"

type CustomParams = {
    [key: string]: string | number;
  };

type Props = {
	content?: any;
	hideBikes?: boolean;
	hideTopModels?: boolean;
	is404Page?: boolean;
    hasDescription?: boolean;
    hasMainCategoryTabs?: boolean;
    customParams?: CustomParams;
    sliderWithDots?: boolean
};

const HomeCatBoxes: React.FC<Props> = ({hideBikes = false,
	hideTopModels = false,
	is404Page,
	content = HomeCatsContentData,
	hasDescription = true,
	hasMainCategoryTabs = true,
	customParams,
	sliderWithDots = true
}) => {
	const router = useRouter()
	const {locale} = router
	const {t} = useTranslation(["common", "filters"])
	const theFirstCatItem = customParams ? CustomHomeCatBoxesSlugsList[locale]?.[0] : (HomeCatBoxesSlugsList[locale]?.[0].itemsList.length ? HomeCatBoxesSlugsList[locale]?.[0].itemsList[0] : null) || {title: "", buttonTitle: "", link: "", slugName: ""}
	const {accessoryFilters, organizationSlug} = useContext(DataContext)
	const {isMobile} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)
	const accessoriesTypes = accessoryFilters?.find(filter => filter.slug === "accessory-type")
	const activeMainCatValue = customParams ? CustomHomeCatBoxesSlugsList[locale]?.[0] : HomeCatBoxesSlugsList[locale][0].title
	const initialBtnTitle = customParams ?
		t("all_button_title", {brand: customParams?.brand, buttonTitle: theFirstCatItem.buttonTitle}) :
		theFirstCatItem.buttonTitle
	const activeMainCatSlug = customParams ? CustomHomeCatBoxesSlugsList[locale]?.[0] : HomeCatBoxesSlugsList[locale][0].slugName
	const [activeMainCategory, setActiveMainCategory] = useState(activeMainCatValue)
	const [activeMainCategorySlug, setActiveMainCategorySlug] = useState(activeMainCatSlug)
	const [activeTitle, setActiveTitle] = useState(theFirstCatItem.title)
	const [activeSlug, setActiveSlug] = useState(theFirstCatItem.slugName)
	const [activeLink, setActiveLink] = useState(theFirstCatItem.link)
	const [activeBtnTitle, setActiveBtnTitle] = useState(initialBtnTitle)
	const [number, setNumber] = useState(5)
	const [offset, setOffset] = useState(0)
	const [homeCatBikesList, setHomeCatBikesList] = useState([])
	const [initialLoadComplete, setInitialLoadComplete] = useState(false)
	const [currentSlide, setCurrentSlide] = useState(0)
	const accessoriesLocalSlug = t("accessories_slug")
	const isAccessory = activeMainCategorySlug === accessoriesLocalSlug
	const productsCount = useRef<number>()
	const isSaleCategory = activeMainCategorySlug === "sale"
	const fetchUrl = useMemo(() => {
		if (isAccessory) {
			const baseParams = `locale=${locale}&limit=${number}&offset=${offset}&accessories=true`
			return `${endpoints.filterProducts}?params=${JSON.stringify({"accessory-type": activeSlug})}&${baseParams}`
		}

		const paramValues = customParams ? {...customParams, categories: activeSlug} : {categories: isSaleCategory ? `${activeSlug},sale_` : activeSlug}

		return `${endpoints.filterProducts}?locale=${locale}&limit=${Number(number)}&offset=${offset}&${urlParams.customInstanceSale}=${organizationSlug}&params=${JSON.stringify(paramValues)}`
	}, [offset, activeSlug, activeLink, activeMainCategory, activeTitle, isAccessory])

	const {data: bikesList, isLoading} = useCache(fetchUrl)

	useEffect(() => {
		if (!initialLoadComplete && !isLoading) {
			setInitialLoadComplete(true)
		}

		if (!isAccessory && bikesList && offset === 0) {
			productsCount.current = bikesList.totalNumberOfProducts
			setHomeCatBikesList(() => bikesList.products)
		} else if (isAccessory && bikesList && offset === 0) {
			productsCount.current = bikesList.totalNumberOfProducts
			setHomeCatBikesList(() => bikesList.products)
		} else if (bikesList) {
			setHomeCatBikesList(prev => prev.concat(bikesList.products))
		}
	}, [isLoading, bikesList, isAccessory])

	const getSlugHandler = (slug: string, link: string, btnTitle: string) => {
		const buttonTitle = isAccessory ?
			t("all_button_title", {brand: "", buttonTitle: btnTitle}) : customParams ?
				t("all_button_title", {brand: customParams?.brand, buttonTitle: btnTitle}) :
				btnTitle
		reactGA?.event({
			categories: gaCategories.homePage,
			action: gaEvents.homePageSubCategoryBoxClicked,
			label: `${gaEvents.homePageSubCategoryBoxClicked}: ${slug} `,
			nonInteraction: false
		})
		setActiveSlug(slug)
		setActiveLink(link)
		setActiveBtnTitle(buttonTitle)
		setNumber(5)
		setOffset(0)
		setCurrentSlide(0)
	}

	const getMainCategoryHandler = (title: string, slug: string, link: string, buttonTitle: string) => {
		reactGA?.event({
			categories: gaCategories.homePage,
			action: gaEvents.homePageMainCategoryBoxClicked,
			label: `${gaEvents.homePageMainCategoryBoxClicked}: ${title} `,
			nonInteraction: false
		})
		setActiveMainCategory(title)
		setActiveTitle(title)
		setActiveBtnTitle(buttonTitle)
		setActiveSlug(slug)
		setActiveLink(link)
		setNumber(5)
		setOffset(0)
		setCurrentSlide(0)
		setActiveMainCategorySlug(slug)
	}

	useEffect(() => {
		const firstCatOfmainCategory = activeMainCategorySlug === accessoriesLocalSlug ?
			null : HomeCatBoxesSlugsList[locale]?.find(el => el.title === activeMainCategory)?.itemsList[0]
		if (firstCatOfmainCategory) {
			setActiveTitle(firstCatOfmainCategory.title)
			setActiveSlug(firstCatOfmainCategory.slugName)
			setActiveLink(firstCatOfmainCategory.link)
		}

		const accessoriesCategory = HomeCatBoxesSlugsList[locale]?.find(cat => cat.slugName === accessoriesLocalSlug)
		if (activeMainCategorySlug === accessoriesLocalSlug && accessoriesCategory) {
			setActiveTitle(accessoriesCategory.title)
			setActiveSlug(accessoriesTypes?.options[0]?.slug)
			setActiveLink(accessoriesTypes?.options[0]?.slug)
		}

		if (isSaleCategory) {
			setActiveTitle(theFirstCatItem.title)
			setActiveSlug(theFirstCatItem.slugName)
			setActiveLink(theFirstCatItem.link)
		}
	}, [activeMainCategory])

	const handleSliderArrowClick = useCallback(debounce(() => {
		reactGA?.event({
			categories: gaCategories.homePage,
			action: gaEvents.homePageCarouselArrowClicked,
			label: `${gaEvents.homePageCarouselArrowClicked}`,
			nonInteraction: false
		})
		if (offset + number < productsCount.current) {
			setOffset(prev => prev + number)
			setNumber(() => 2)
		}
	}, 300), [number, offset, productsCount])

	return (
		<ErrorBoundary fallback={<p>Something went wrong...</p>}>
			<section id={"cat-boxes"}>
				<div className={"pb-0"}>
					<HomeCatBoxesHeader contentData={content}
						hasDescription={hasDescription}/>
					<div className="container-md">
						{hasMainCategoryTabs &&
						<MainCategoryTabs categories={HomeCatBoxesSlugsList[locale]}
							activeCategory={activeMainCategory}
							onCategoryClick={getMainCategoryHandler} />}
						<SubCategoryTabs isAccessory={isAccessory}
							items={isAccessory ? accessoriesTypes?.options : customParams ? CustomHomeCatBoxesSlugsList[locale] :
								HomeCatBoxesSlugsList[locale]?.find(category => category.title === activeMainCategory)?.itemsList || []}
							activeSlug={activeSlug}
							onSubCategoryClick={getSlugHandler} />
						<div className={`bikes-slider-wrapper pt-4 ${isMobile ? "d-block" : "d-none d-md-flex"} mx-auto justify-content-center position-relative`}
							style={{minHeight: "auto", maxHeight: 700}}>
							{!isLoading || initialLoadComplete ? (
								homeCatBikesList?.length > (isMobile ? 1 : 4) ? (
									<SliderContent
										productsList={homeCatBikesList}
										handleSliderArrowClick={handleSliderArrowClick}
										setCurrentSlide={setCurrentSlide}
										currentSlide={currentSlide}
										totalItems={homeCatBikesList.length}
										slideWidth={isMobile ? 196 : 305}
										arrows={!isMobile}
										dots={!isMobile}
									/>
								) : (
									homeCatBikesList?.map((bike, idx) => {
										const isVisibleFirst = idx === currentSlide
										const isVisibleLast = idx === homeCatBikesList.length - 1

										const borderRadius = isVisibleFirst ?
											"24px 0px 0px 24px" :
											isVisibleLast ?
												"0px 24px 24px 0px" :
												"0px"
										return (
											<div
												key={idx}
												onClick={() => {
													reactGA?.event({
														categories: gaCategories.homePage,
														action: gaEvents.topModels,
														label: bike.name,
														nonInteraction: false
													})
												}}
												className={isMobile ? "" : "pt-2 h-100 col-3"}
												style={{paddingLeft: isMobile ? "12px" : "0"}}>
												<BikesCollectionItem
													isCarouselElement={isMobile}
													bike={bike}
													withOneImage
													isRowForMobile={isMobile}
													borderRadius={borderRadius}
													isIkeaDesign={homeCatBikesList.length > 1}
													cardWidth={"100%"}

												/>
											</div>
										)
									})
								)
							) : (
								<div className="pt-5"
									style={{height: 497}}>
									<Spinner />
								</div>
							)}

						</div>

					</div>
					<div className="container d-flex justify-content-center col-12 mt-md-3 pt-md-2 pb-5 pb-md-0">
						<HomeCatsButton activeMainCategorySlug={activeMainCategorySlug}
							accessoriesLocalSlug={accessoriesLocalSlug}
							activeBtnTitle={t(activeBtnTitle)}
							customParams={customParams}
							activeLink={activeLink}
							isSaleCategory={isSaleCategory}
						/>
					</div>
					<div className="mt-4 d-none d-md-block container">
						<ComparisonPLPFixedComponent sticky={false} />
					</div>
					{hideTopModels ? null : <div className="ps-3 container-md ps-md-0 mt-5">
						<CatBikesListing catName={"Featured"}
							noButton
							featured/>
					</div>}
				</div>
			</section>
		</ErrorBoundary>
	)
}

export default HomeCatBoxes
