import Typography from "../../reusable/Typography/Typography"
import React from "react"

type Props = {
	items: any[];
	activeSlug?: string;
	onSubCategoryClick?: any;
	isAccessory?: boolean
};

const SubCategoryTabs: React.FC<Props> = ({items, activeSlug, onSubCategoryClick, isAccessory}) => {
	return (
		<ul className="px-0 ms-0 d-flex align-items-center justify-content-md-center gap-2 list-unstyled mb-0 scrollbar-hide bike-categories ms-2 ms-md-0"
			style={{height: 36, overflowX: "scroll"}}>
			{items?.map((item, idx) => {
				const slug = isAccessory ? item.slug : item.slugName
				const buttonTitle = isAccessory ? item.name : item.buttonTitle
				return (
					<li key={idx}
						className="px-3 d-flex align-items-center second-level-filter"
						style={{
							height: 36,
							padding: activeSlug === slug ? "10px 14px" : "10px 16px",
							borderRadius: 16,
							cursor: "pointer",
							backgroundColor: activeSlug === slug ? "#FFE9C2" : "#fff",
							border: activeSlug === slug ? "1px solid #FFE9C2" : "1px solid #DEE2E6",
							color: "#212529"
						}}
						onClick={() => onSubCategoryClick(slug, item.link, buttonTitle)}>
						<Typography variant={activeSlug === slug ? "bodySmBold" : "bodySm"}
							className={"text-nowrap"}>
							{isAccessory ? item.name : item.title}
						</Typography>
					</li>
				)
			})}
		</ul>
	)
}

export default SubCategoryTabs
