import React from "react"
import Button from "../../reusable/components/Button"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"

type Props = {
    activeMainCategorySlug: string
    accessoriesLocalSlug: string,
    activeBtnTitle: string,
    customParams?: any,
    activeLink?: any,
    isSaleCategory?: boolean
}

const HomeCatsButton: React.FC<Props> = ({
	activeMainCategorySlug,
	accessoriesLocalSlug,
	activeBtnTitle,
	customParams,
	activeLink,
	isSaleCategory
}) => {
	return (
		<>
			<Button
				style={{marginTop: "2rem"}}
				label={activeBtnTitle}
				href={
					activeMainCategorySlug === accessoriesLocalSlug ?
						filterUrl(
							{
								[urlParams.categories]: accessoriesLocalSlug
							},
							null,
							null
						) :
						customParams ?
							filterUrl(
								{
									[urlParams.categories]: activeLink,
									brand: customParams?.brand

								},
								null,
								null
							) :
							filterUrl(
								{
									[urlParams.categories]: activeLink
								},
								null,
								null
							)
				}
				color="#212529"
				textColor="#212529"
				bgColor="#FEC04D"
				high
				paddingY={"3"}
			/>

		</>
	)
}

export default HomeCatsButton
