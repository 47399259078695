import Typography from "../../reusable/Typography/Typography"
import React from "react"

type Props = {
	categories: any[];
	activeCategory?: string;
	onCategoryClick?: any;
};

const MainCategoryTabs: React.FC<Props> = ({categories, activeCategory, onCategoryClick}) => {
	return (
		<ul className="container mb-3 mt-2 mt-md-0 d-flex align-items-center justify-content-md-center list-unstyled bikes-tabs scrollbar-hide"
			style={{height: 36, width: "100%", overflowX: "scroll"}}>
			{categories.map((category, idx) => (
				<li key={idx}
					className={`bike-tab ${activeCategory === category.title ? "bike-tab-active" : ""} px-3 d-flex align-items-center justify-content-center first-level-filter`}
					onClick={() => onCategoryClick(category.title, category.slugName, category.link, category.buttonTitle)}>
					<Typography variant={"heading3MdBodyLgBold"}
						className={"text-nowrap"}>{category.title}</Typography>
				</li>
			))}
		</ul>
	)
}

export default MainCategoryTabs
